import * as React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import BannerEffect from "../themes/custom/jobs_front/assets/img/bannerEffect.png";
import AboutUsBanner from "../assets/img/about-us.jpg";
import One from "../assets/img/01P.svg";
import Two from "../assets/img/02P.svg";
import Three from "../assets/img/03P.svg";
import Four from "../assets/img/04P.svg";
const AboutUs = () => {
  const renderCard = (children) => {
    return (
      <CSSTransition classNames="example" timeout={{ enter: 500, exit: 300 }}>
        <div className="hub-slider-slides">
          <ul>
            <li className="statistics">{children}</li>
          </ul>
        </div>
      </CSSTransition>
    );
  };
  const cards = [
    <>
      <span style={{ fontSize: "28px" }}>
        <div className="counter mr-1 ">
          More than 30 professionals dedicated full-time to the project's
          objectives.
        </div>
      </span>
    </>,
  ];
  return (
    <Layout>
      <SEO
        title="About Us | Visit Tunisia Project"
        description="About USAID Activity"
      />
      <main role="main">
        <div className="container-fluid">
          <div className="region region-banner">
            <div
              id="block-usaidtunisiajobs"
              className="block block-block-content block-block-content81fd0fe9-bba5-415c-a37d-7e066fe858be"
            >
              <div className="intern-banner">
                <div className="overlay">
                  <img src={BannerEffect} alt="" />
                </div>
                <img src={AboutUsBanner} alt="" />
                <div className="caption">
                  <h1 className="animate__animated animate__fadeInUp animate__slow">
                    <div className="field field--name-field-titre field--type-string field--label-hidden field__item">
                      About Us
                    </div>
                  </h1>
                  <div className="animate__animated animate__fadeInUp animate__slow animate__delay-1s">
                    <div className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden field__item">
                      <p>
                        Building{" "}
                        <span>a diverse, competitive and resilient</span>{" "}
                        tourism sector
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="region region-content">
            <div data-drupal-messages-fallback className="hidden" />
            <div
              id="block-jobs-front-content"
              className="block block-system block-system-main-block"
            >
              <article
                data-history-node-id={45}
                role="article"
                about="/en/about-us"
                typeof="schema:WebPage"
                className="node node--type-page node--view-mode-full"
              >
                <span
                  property="schema:name"
                  content="About us"
                  className="rdf-meta hidden"
                />
                <div className="node__content">
                  <div className="field field--name-field-bricks field--type-bricks field--label-hidden field__items">
                    <div>
                      <section className="about-us">
                        <div className="brick brick--type--about-us brick--id--56 eck-entity container">
                          <div className="more-about-us d-flex flex-lg-row flex-column">
                            <div
                              className="left-side aos-init aos-animate"
                              data-aos="fade-up"
                            >
                              <h2 className="title-left">
                                <div className="field field--name-title field--type-string field--label-hidden field__item">
                                  Visit Tunisia at a Glance
                                </div>
                              </h2>
                              <div className="left-side-paragh">
                                <p>
                                  The USAID Visit Tunisia Activity works with
                                  public and private sector partners to enhance
                                  and diversify the tourism industry, by
                                  improving international marketing and quality
                                  standards, driving investment, delivering
                                  technical assistance to promising tourism
                                  businesses, and helping develop innovative
                                  tourism products and experiences.
                                </p>
                                <p>
                                  Bolstering Tunisia’s sustainable tourism
                                  markets, particularly in underserved regions,
                                  and expanding opportunities for women and
                                  youth in the industry, are central themes of
                                  our work.
                                </p>
                                <p>
                                  Visit Tunisia delivers assistance through four
                                  strategic lenses, as illustrated here.
                                </p>
                              </div>
                            </div>
                            <div className="right-side">
                              <div
                                className="hub-slider about-number-section aos-animate"
                                data-aos="fade-up"
                                data-aos-easing="ease-out-cubic"
                              >
                                <TransitionGroup
                                  transitionName="fade"
                                  transitionEnterTimeout={500}
                                  transitionLeaveTimeout={300}
                                >
                                  {cards.map((card) => renderCard(card))}
                                </TransitionGroup>
                              </div>
                              <div className="schema-wrapper">
                                <div className="schema d-flex flex-wrap">
                                  <div
                                    className="card aos-animate"
                                    data-aos="fade-down-right"
                                    data-aos-easing="ease-out-cubic"
                                  >
                                    <img src={One} alt="" />
                                    <div className="inner-text">
                                      <p>
                                        <span>
                                          Marketing and Communications{" "}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className="card aos-animate"
                                    data-aos="fade-down-left"
                                    data-aos-easing="ease-out-cubic"
                                  >
                                    <img src={Two} alt="" />
                                    <div className="inner-text">
                                      <p>
                                        <span>Product Development</span>
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className="card aos-animate"
                                    data-aos="fade-up-right"
                                    data-aos-easing="ease-out-cubic"
                                  >
                                    <img src={Three} alt="" />
                                    <div className="inner-text">
                                      <p>
                                        <span>Enabling Environment</span>
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className="card aos-animate"
                                    data-aos="fade-up-left"
                                    data-aos-easing="ease-out-cubic"
                                  >
                                    <img src={Four} alt="" />
                                    <div className="inner-text">
                                      <p>
                                        <span>Investment and Innovation</span>
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className="inner-circle aos-animate"
                                    data-aos="fade-in"
                                    data-aos-easing="ease-out-cubic"
                                    data-aos-delay={300}
                                    style={{ zIndex: "1" }}
                                  >
                                    <span>
                                      <div className="clearfix text-formatted field field--name-field-inner-circle-titl field--type-text field--label-hidden field__item">
                                        A more competitive tourism industry
                                      </div>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};
export default AboutUs;
